import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { RAdvantagesSection } from 'views/r/advantages-section';
import {
    LATEST_RELATED_ARTICLES_SETTINGS,
    PERKS_LIST,
} from 'views/r/constants';
import { ContactWithExpertSection } from 'views/r/contact-with-expert-section';
import { HireDataScienceDevelopersSection } from 'views/r/hire-developers-section';
import { RBusinessBenefitsSection } from 'views/r/r-business-benefits-section';
import { RMainSection } from 'views/r/r-main-section';
import { RealResultsSection } from 'views/r/real-results-section';
import { CONSTANTS } from 'constants/styles/constants';
import { FooterSection } from 'modules/footer-section';
import { WorkWithMRSection } from 'modules/technologies/work-with-mr-section';
import { array } from 'prop-types';

const Container = styled.div`
    width: 100%;
    padding-top: ${CONSTANTS.MARGIN.PAGE};
`;

const Props = {
    faqData: array.isRequired,
};

export const RView = ({ faqData }) => {
    const { formatMessage } = useIntl();

    return (
        <Container>
            <RMainSection />
            <RAdvantagesSection />
            <HireDataScienceDevelopersSection />
            <RBusinessBenefitsSection />
            <RealResultsSection />
            <WorkWithMRSection
                title="r.workWithMobileReality.title"
                firstRowDescriptions={[
                    'r.workWithMobileReality.atMobileReality',
                    'r.workWithMobileReality.agileDevelopment',
                ]}
                secondRowDescriptions={[
                    'r.workWithMobileReality.collaborativeApproach',
                ]}
                perksData={PERKS_LIST}
                firstRowAlt={formatMessage({
                    id: 'r.workWithMobileReality.firstRowAlt',
                })}
                secondRowAlt={formatMessage({
                    id: 'r.workWithMobileReality.secondRowAlt',
                })}
            />
            <ContactWithExpertSection />
            <FooterSection
                faqSection={{
                    title: faqData.title,
                    data: faqData.data,
                    parseRawAnswer: true,
                }}
                latestRelatedArticles={LATEST_RELATED_ARTICLES_SETTINGS}
            />
        </Container>
    );
};

RView.propTypes = Props;
