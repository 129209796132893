import React from 'react';
import { BoxElement } from 'components/box-element';
import { Container } from 'components/container';
import { gaTrackedEvents } from 'constants/ga-tracked-events';

export const HireDataScienceDevelopersSection = () => {
    return (
        <Container>
            <BoxElement
                title="r.hireDataScienceSpecialists"
                googleAnalytics={gaTrackedEvents.R.CTA.BOOK_CONSULTATION}
            />
        </Container>
    );
};
