import React from 'react';
import { MainSectionTemplate } from 'components/main-section-template';
import { gaTrackedEvents } from 'constants/ga-tracked-events';

export const RMainSection = () => {
    return (
        <MainSectionTemplate
            title="r.machineLearning"
            subTitle="r.leaveYourCompetition"
            googleAnalytics={gaTrackedEvents.R.CTA.ESTIMATE_PROJECT}
        />
    );
};
