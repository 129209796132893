import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Paragraph } from 'components/paragraph';
import { arrayOf, bool, node, shape, string } from 'prop-types';

const SContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    margin-top: -2rem;
    width: ${({ fillWidth }) => (fillWidth ? '100%' : 'auto')};
`;

const SLogoWrapper = styled.div`
    min-height: 5rem;
    display: flex;
    align-items: center;
`;

const SWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem 0.813rem 0;
    flex: 1;
`;

const SParagraph = styled(Paragraph)`
    white-space: pre-wrap;
    text-align: center;
    margin-top: 1.688rem;
`;

const STitle = styled.h3`
    margin-top: 4rem;
`;

export const Results = ({ results, fillWidth }) => {
    const resultsToRender = results.map(
        ({ logo, description, title }, index) => (
            <SWrapper key={index}>
                <SLogoWrapper>{logo}</SLogoWrapper>
                {title && (
                    <STitle>
                        <FormattedMessage id={title} />
                    </STitle>
                )}
                {description ? (
                    <SParagraph>
                        <FormattedMessage id={description} />
                    </SParagraph>
                ) : null}
            </SWrapper>
        ),
    );
    return <SContainer {...{ fillWidth }}>{resultsToRender}</SContainer>;
};

Results.propTypes = {
    results: arrayOf(
        shape({
            logo: node.isRequired,
            description: string,
        }),
    ).isRequired,
    fillWidth: bool,
};

Results.defaultProps = {
    fillWidth: false,
};
