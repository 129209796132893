import React from 'react';
import { R_ADVANTAGES } from 'views/r/advantages-section/constants';
import { AdvantagesSectionTemplate } from 'components/advantages-section-template';
import { Container } from 'components/container';

export const RAdvantagesSection = () => {
    return (
        <Container>
            <AdvantagesSectionTemplate
                title="r.advantagesOfMachineLearning"
                secondParagraph="r.theAdvantagesOfMachineLearning"
                advantages={R_ADVANTAGES}
            />
        </Container>
    );
};
