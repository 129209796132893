import React from 'react';
import { BLOG_POST_TAGS } from 'constants/blog-post-tags';
import { PATHS } from 'constants/paths';
import JavascriptSvg from 'svgs/blog/javascript.svg';
import DigitalAdvertisingIcon from 'svgs/icons/ic-digital-advertising.svg';
import FinanceIcon from 'svgs/icons/ic-finance.svg';
import HealthcareIcon from 'svgs/icons/ic-health-care.svg';
import IndustryIcon from 'svgs/icons/ic-industry.svg';
import LogisticsIcon from 'svgs/icons/ic-logistics.svg';
import OnlineIcon from 'svgs/icons/ic-online.svg';
import PublicAdministrationIcon from 'svgs/icons/ic-public-administration.svg';
import RealEstateIcon from 'svgs/icons/ic-real-estate.svg';
import SportsIcon from 'svgs/icons/ic-sports.svg';
import CertifiedIcon from 'svgs/technologies/work-with-mr/award.svg';
import MarketIcon from 'svgs/technologies/work-with-mr/calendar-tick.svg';
import MaintainersIcon from 'svgs/technologies/work-with-mr/code.svg';
import ClientsIcon from 'svgs/technologies/work-with-mr/people.svg';

export const R_BUSINESS_BENEFITS = [
    {
        icon: <OnlineIcon />,
        text: 'r.onlineTrading',
        description: 'r.recommendationSystemsForUsers',
    },
    {
        icon: <HealthcareIcon />,
        text: 'r.healthCare',
        description: 'r.diseasePrediction',
    },
    {
        icon: <LogisticsIcon />,
        text: 'r.logistics',
        description: 'r.planningAndOptimization',
    },
    {
        icon: <DigitalAdvertisingIcon />,
        text: 'r.digitalAdvertising',
        description: 'r.automatedContent',
    },
    {
        icon: <FinanceIcon />,
        text: 'r.finance',
        description: 'r.scoring',
    },
    {
        icon: <IndustryIcon />,
        text: 'r.industry',
        description: 'r.predictiveAnalytics',
    },
    {
        icon: <RealEstateIcon />,
        text: 'r.realEstate',
        description: 'r.searchAndOffer',
    },
    {
        icon: <PublicAdministrationIcon />,
        text: 'r.publicAdministration',
        description: 'r.forecastingEmployment',
    },
    {
        icon: <SportsIcon />,
        text: 'r.sports',
        description: 'r.selectionOfPromising',
    },
];

export const PERKS_LIST = [
    {
        title: 'r.workWithMobileReality.onTheMarket',
        icon: <MarketIcon />,
    },
    {
        title: 'r.workWithMobileReality.certifiedDevelopers',
        icon: <CertifiedIcon />,
    },
    {
        title: 'r.workWithMobileReality.ownersAndMaintainers',
        icon: <MaintainersIcon />,
    },
    {
        title: 'r.workWithMobileReality.experiencedInWorking',
        icon: <ClientsIcon />,
    },
];

export const LATEST_RELATED_ARTICLES_SETTINGS = {
    hideTitle: true,
    showAllArticles: true,
    tags: [BLOG_POST_TAGS.DATA_SCIENCE_ML, BLOG_POST_TAGS.GEN_AI],
    button: {
        to: `${PATHS.BLOG}/${PATHS.JAVASCRIPT}`,
        label: 'r.footer.button',
    },
    categoryBanner: {
        title: 'r.footer.title',
        description: 'r.footer.description',
        svg: JavascriptSvg,
    },
};
