export const R_ADVANTAGES = [
    {
        title: 'r.fastInformation',
        description: 'r.theSpeed',
    },
    {
        title: 'r.usageInTheMedicalIndustry',
        description: 'r.manyHospitals',
    },
    {
        title: 'r.simplification',
        description: 'r.companiesRegularly',
    },
    {
        title: 'r.spamDetection',
        description: 'r.spamIsMessages',
    },
    {
        title: 'r.improvingAccuracy',
        description: 'r.financeForms',
    },
    {
        title: 'r.improvingCustomerSegmentation',
        description: 'r.customerSegmentation',
    },
];
