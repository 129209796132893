import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { HeaderWithLine } from 'components/header-with-line';
import { Paragraph } from 'components/paragraph';
import { SectionTiles } from 'modules/business-benefits-section/components/section-tiles';
import { arrayOf, bool, node, shape, string } from 'prop-types';

const SContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const SHeaderSecond = styled(HeaderWithLine)`
    white-space: pre-wrap;
    margin: 0 0 1.5rem 0;
    &::before {
        background-color: var(--primary-color);
    }
`;

const SParagraph = styled(Paragraph)`
    text-align: center;
    white-space: pre-wrap;
    font-size: 1.125rem;
    margin-bottom: 3.75rem;
`;

export const SectionTilesTemplate = ({
    title,
    description,
    tilesData,
    className,
    singleTileFullWidth,
}) => {
    return (
        <SContainer className={className}>
            {title && (
                <SHeaderSecond description={description} lineTop>
                    <FormattedMessage id={title} />
                </SHeaderSecond>
            )}
            {description && (
                <SParagraph className="description">
                    <FormattedMessage id={description} />
                </SParagraph>
            )}
            {tilesData && (
                <SectionTiles
                    tilesData={tilesData}
                    singleTileFullWidth={singleTileFullWidth}
                />
            )}
        </SContainer>
    );
};

SectionTiles.propTypes = {
    className: string,
    title: string,
    description: string,
    singleTileFullWidth: bool,
    tilesData: arrayOf(
        shape({
            icon: node,
            text: string,
            description: string,
        }),
    ),
};

SectionTiles.defaultProps = {
    title: '',
    description: '',
    singleTileFullWidth: false,
};
