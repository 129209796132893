import React from 'react';
import { R_RESULTS } from 'views/r/real-results-section/constants';
import { CardSectionTemplate } from 'components/card-section-template';
import { Container } from 'components/container';

export const RealResultsSection = () => {
    return (
        <Container>
            <CardSectionTemplate title="r.rUseCases" results={R_RESULTS} />
        </Container>
    );
};
