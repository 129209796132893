import React from 'react';
import FoursquareLogo from 'svgs/r/foursquare-logo.svg';
import GoogleLogo from 'svgs/r/google-logo.svg';
import MicrosoftLogo from 'svgs/r/microsoft-logo.svg';
import MozillaLogo from 'svgs/r/mozilla-logo.svg';

export const R_RESULTS = [
    {
        logo: <MicrosoftLogo />,
        description: 'r.microsoftUsesR',
    },
    {
        logo: <FoursquareLogo />,
        description: 'r.rWorks',
    },
    {
        logo: <MozillaLogo />,
        description: 'r.mozillaUsesR',
    },
    {
        logo: <GoogleLogo />,
        description: 'r.googleUsesR',
    },
];
