import React from 'react';
import { RView } from 'views/r';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import messages from 'constants/translations/technologies/r.json';
import { graphql } from 'gatsby';
import Layout from 'layouts/standard';
import { object } from 'prop-types';
import { formatFaqData } from 'utilities/contentful';

const RPage = ({ location, data }) => {
    const faqData = formatFaqData(data?.allContentfulFaq.nodes[0]);

    return (
        <Layout location={location} messages={messages}>
            <RView {...{ faqData }} />
        </Layout>
    );
};

RPage.propTypes = {
    location: object.isRequired,
};

export default RPage;

export const Head = () => <SEO tags={TAGS.R} />;

export const pageQuery = graphql`
    query RPageQuery {
        allContentfulFaq(filter: { slug: { eq: "technologies/r" } }) {
            nodes {
                ...FaqFragment
            }
        }
    }
`;
