import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { R_BUSINESS_BENEFITS } from 'views/r/constants';
import { HeaderThird } from 'components/header-third';
import { CONSTANTS } from 'constants/styles/constants';
import { BusinessBenefitsSection } from 'modules/business-benefits-section';

const SRBusinessBenefitsSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: ${CONSTANTS.MARGIN.SECTION};
`;

const SHeaderThird = styled(HeaderThird)`
    font-weight: bold;
    text-align: center;
    padding: 0 1rem;
    font-size: 1rem;
`;

export const RBusinessBenefitsSection = () => {
    return (
        <SRBusinessBenefitsSection>
            <BusinessBenefitsSection
                title="r.businessBenefits"
                description="r.companiesUseDataScience"
                data={R_BUSINESS_BENEFITS}
            />
            <SHeaderThird>
                <FormattedMessage id="r.andThisIsJust" />
            </SHeaderThird>
        </SRBusinessBenefitsSection>
    );
};
