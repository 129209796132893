import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Paragraph } from 'components/paragraph';
import { CONSTANTS } from 'constants/styles/constants';
import { arrayOf, bool, node, shape, string } from 'prop-types';

const SContainer = styled.div`
    justify-items: center;
    width: 100%;
    gap: 2rem;
    margin-top: -2rem;
    display: grid;
    grid-template-columns: ${({ isThreeTiles }) =>
        isThreeTiles ? 'repeat(3, 1fr)' : 'repeat(2, 1fr)'};

    ${CONSTANTS.MEDIA.max1024`
        grid-template-columns: 1fr;
        gap: 1rem;
    `}
`;

const STile = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem 2rem 0;
    max-width: 25rem;
    ${({ singleTileFullWidth }) => singleTileFullWidth && 'width: 100%'};
`;

const SIconWrapper = styled.div`
    height: 5.625rem;
    display: flex;
    align-items: flex-end;
`;

const STitle = styled.h3`
    margin-top: 1.875rem;
    font-weight: 600;
    text-align: center;
`;

const SDescription = styled(Paragraph)`
    margin-top: 1rem;
    text-align: center;
`;

export const SectionTiles = ({ tilesData, singleTileFullWidth }) => {
    const isThreeTiles = tilesData.length === 3;

    const sectionTilesToRender = tilesData.map(
        ({ icon, text, description }, index) => (
            <STile key={index} singleTileFullWidth={singleTileFullWidth}>
                <SIconWrapper>{icon}</SIconWrapper>
                <STitle>
                    <FormattedMessage id={text} />
                </STitle>
                {description ? (
                    <SDescription className="benefit-description">
                        <FormattedMessage id={description} />
                    </SDescription>
                ) : null}
            </STile>
        ),
    );
    return (
        <SContainer isThreeTiles={isThreeTiles} className="tiles-container">
            {sectionTilesToRender}
        </SContainer>
    );
};

SectionTiles.propTypes = {
    tilesData: arrayOf(
        shape({
            icon: node.isRequired,
            text: string.isRequired,
            description: string,
        }),
    ),
    singleTileFullWidth: bool,
};

SectionTiles.defaultProps = {
    singleTileFullWidth: false,
};
