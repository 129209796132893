import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { HeaderWithLine } from 'components/header-with-line';
import { Paragraph } from 'components/paragraph';
import { CONSTANTS } from 'constants/styles/constants';
import { arrayOf, node, shape, string } from 'prop-types';

import { Results } from './components/results';

const SContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const SCardContainer = styled.div`
    width: 100%;
    padding: 0 ${CONSTANTS.MARGIN.SPACE};
    box-shadow: 0 1.125rem 2.375rem var(--box-shadow-dark-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5.813rem 5.813rem 3.938rem;
    ${CONSTANTS.MEDIA.max1024`
        margin: 0 1rem;
        padding: 3.938rem 1rem;
    `}
`;

const SHeaderSecond = styled(HeaderWithLine)`
    white-space: pre-wrap;
    margin: 0 0 1.75rem 0;
    &::before {
        background-color: var(--primary-color);
    }
`;

const SParagraph = styled(Paragraph)`
    text-align: center;
    white-space: pre-wrap;
    margin-bottom: 5.125rem;
`;

export const CardSectionTemplate = ({ title, paragraph, results }) => {
    return (
        <SContainer>
            <SCardContainer>
                <SHeaderSecond lineTop>
                    <FormattedMessage id={title} />
                </SHeaderSecond>
                {paragraph ? (
                    <SParagraph>
                        <FormattedMessage id={paragraph} />
                    </SParagraph>
                ) : null}
                <Results results={results} />
            </SCardContainer>
        </SContainer>
    );
};

CardSectionTemplate.propTypes = {
    title: string.isRequired,
    paragraph: string,
    results: arrayOf(
        shape({
            logo: node.isRequired,
            description: string,
        }),
    ),
};
