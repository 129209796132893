import React from 'react';
import { Container } from 'components/container';
import { SectionTilesTemplate } from 'modules/business-benefits-section/components/section-tiles-template';
import { arrayOf, node, shape, string } from 'prop-types';

export const BusinessBenefitsSection = ({
    title,
    description,
    className,
    data,
}) => {
    return (
        <Container>
            <SectionTilesTemplate
                title={title}
                description={description}
                tilesData={data}
                className={className}
                singleTileFullWidth={true}
            />
        </Container>
    );
};

BusinessBenefitsSection.propTypes = {
    title: string,
    description: string,
    className: string,
    data: arrayOf(
        shape({
            icon: node,
            text: string,
            description: string,
        }),
    ),
};

BusinessBenefitsSection.defaultProps = {
    className: '',
    title: '',
    description: '',
};
